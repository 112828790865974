import React from "react";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";

function StoreDidNotUpdateMessage() {
	return (
		<div className="border-dashed border-2 border-jane-500 flex flex-col items-center text-center rounded-lg p-4 text-lg md:text-xl gap-2  text-jane-500">
			<MdOutlineProductionQuantityLimits className="w-12 h-12 md:w-20 md:h-20" />
			בית המרקחת לא עדכן את המלאי זמן רב, לכן המלאי אינו זמין
		</div>
	);
}

export default StoreDidNotUpdateMessage;
