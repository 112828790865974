import React, { useCallback, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { classNames } from "util/common";
import { SearchIcon, XIcon } from "@heroicons/react/solid";

//? Since we use it only for search bar - we should rename it to DebounceSearchInput?
export const DebounceInput = ({
	placeholder,
	disabled = false,
	onBounce = inputVal => {},
	timeoutMs = 1000,
	className,
	withGridLayout,
	searchQuery,
}) => {
	const [value, setValue] = useState(searchQuery ?? "");

	useEffect(() => {
		setValue(searchQuery ?? "");
	}, [searchQuery]);

	const debounceFn = useCallback(_debounce(handleDebounceFn, timeoutMs), []);

	function handleDebounceFn(inputValue) {
		onBounce(inputValue);
	}

	useEffect(() => {
		debounceFn(value);
	}, [value]);

	function handleChange(event) {
		setValue(event.target.value);
	}

	return (
		<div className={"relative " + className}>
			{!!value ? (
				<XIcon
					onClick={() => {
						onBounce(""), setValue("");
					}}
					className="h-full w-5 absolute left-2 top-0 bottom-0 text-jane-400 cursor-pointer hover:text-jane-500"
				/>
			) : (
				<SearchIcon
					className=" h-full w-5 absolute left-2 top-0 bottom-0 text-jane-400 pointer-events-none"
					aria-hidden="true"
				/>
			)}
			<input
				type="search"
				className={classNames(
					"block w-full border-gray-300 py-1 sm:py-2 rounded-md text-base min-h-[30px] h-full pl-[24px] remove-clear transition-all disabled:bg-gray-100 disabled:border-jane-200 focus:ring-jane-500 focus:border-jane-500",
				)}
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
				disabled={disabled}
				maxLength={50}
				autoComplete="off"
			/>
		</div>
	);
};
