import { ViewListIcon } from "@heroicons/react/solid";
import { useResponsive } from "components/hooks/useResponsive";
import React, { useState } from "react";
import { StoresUpdateTime } from "components/stores/StoresUpdateTime";
import { classNames, LAYOUT, useIsTabletMode } from "util/common";
import SingleStoreContainer from "components/table-containers/SingleStoreContainer";
import SingleProductContainer from "components/table-containers/SingleProductContainer";
import StoresTableContainer from "components/table-containers/StoresTableContainer";
import TableFiltersWrapper from "components/filters/TableFiltersWrapper";
import StoreDidNotUpdateMessage from "components/ui/StoreDidNotUpdateMessage";
import FilterTags from "components/filters/FilterTags";
import FiltersButton from "components/ui/FiltersButton";
import MapTableIcon from "components/maps/utils/MapTableIcon";
import { DebounceInput } from "./DebounceInput";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { showSearchbarAtom } from "components/jotai/searchAtom";
import { useAtom } from "jotai";
import StoresQuickFilter from "components/ui/StoresQuickFilter";
import DesiredCartContainer from "components/table-containers/DesiredCartContainer";
import LayoutButton from "components/ui/LayoutButton";

export function TableContainer({
	tbl, // useServerTable instance.
	title,
	subtitle,
	prependStoreContent,
	prependStoreSortByContent,
	prependContent,
	subContent,
	searchable,
	onSearch = q => {},
	filters = [],
	filterValues = [],
	onFiltersChanged = async (fv, condition = "in") => {},
	naked = false,
	children,
	hideOnScroll = false,
	hideLayoutButton = false,
	isFiltersOpen = false,
	layout,
	isHomeWidget = false,
	is48HoursPast = false,
	customButtons = null,
	isQuickfilter = false,
	isDesiredCart = false,
	isSingleProduct = false,
	isSingleStore = false,
	isWithDiscountsFilter = false,
	isWithShippingFilter = false,
	isWithOpenFilter = false,
	isWithLocationButton = false,
	isStoresTable = false,
	isShabbatPage = false,
	isOrdersEnabled = false,
	prependSortByContent = null,
	itemId,
	searchQuery,
	isTableWithMap,
	isTableView = true,
	setIsTableView,
	plainHeader = false,
	withMap,
	searchPlaceholder,
	isMainTable = false,
}) {
	const isInIframe = useIsInIframe();
	const isTabletMode = useIsTabletMode();
	const screen = useResponsive();
	const [showFilters, setShowFilters] = useState(
		!!isFiltersOpen || !!screen.is("lg") || false,
	);
	const [showSearchbar, setShowSearchbar] = useAtom(showSearchbarAtom);
	const withGridLayout = tbl.layoutOptions.some(
		option => option === LAYOUT.GRID,
	);

	const { selectedIndexes, totalItems } = tbl;
	const itemsCountLabel = !!is48HoursPast
		? "(0)"
		: !!selectedIndexes.length
		? `(${selectedIndexes.length}/${totalItems})`
		: `(${totalItems})`;

	const onSearchBounce = q => {
		if (!searchable) {
			return;
		}
		q = q || null;
		onSearch(q);
	};

	const filtersChanged = async fv => {
		const newFilters = fv.filter(v => !!v.value && !!v.value.length);
		await onFiltersChanged(newFilters);
	};

	const hasFilters = !!filters.length;
	const onShowFiltersClicked = () => {
		setShowFilters(!showFilters);
	};

	const iconLayout = hideLayoutButton ? null : layout === "grid" ? (
		<ViewListIcon
			className={classNames(
				"h-6 w-6 sm:h-6.5 sm:w-6.5 text-gray-400 cursor",
				isTabletMode && "sm:w-8 sm:h-8",
			)}
		/>
	) : (
		<BsFillGrid3X3GapFill
			className={classNames(
				"h-6 w-6 sm:h-6.5 sm:w-6.5 text-gray-400 cursor",
				isTabletMode && "sm:w-8 sm:h-8",
			)}
		/>
	);

	if (!!naked) {
		return children;
	}

	const layoutChangeValueForEventId =
		layout === "grid" ? "list" : layout === "list" ? "grid" : "";

	if (isDesiredCart) {
		return (
			<DesiredCartContainer
				tbl={tbl}
				prependStoreContent={prependStoreContent}
				prependContent={prependContent}
				title={title}
				isTableView={isTableView}
				setIsTableView={setIsTableView}
				subtitle={subtitle}
				prependStoreSortByContent={prependStoreSortByContent}
				withGridLayout={withGridLayout}
				layoutChangeValueForEventId={layoutChangeValueForEventId}
				iconLayout={iconLayout}
				searchable={searchable}
				onSearchBounce={onSearchBounce}
				hasFilters={hasFilters}
				onShowFiltersClicked={onShowFiltersClicked}
				showFilters={showFilters}
				filters={filters}
				filterValues={filterValues}
				filtersChanged={filtersChanged}
				subContent={subContent}
				isWithDiscountsFilter={isWithDiscountsFilter}
				searchQuery={searchQuery}
				setShowFilters={setShowFilters}
				showSearchbar={showSearchbar}
				searchPlaceholder={searchPlaceholder}
			>
				{children}
			</DesiredCartContainer>
		);
	}

	if (isSingleStore) {
		return (
			<SingleStoreContainer
				prependStoreContent={prependStoreContent}
				hideOnScroll={hideOnScroll}
				prependContent={prependContent}
				tbl={tbl}
				title={title}
				itemsCountLabel={itemsCountLabel}
				subtitle={subtitle}
				prependStoreSortByContent={prependStoreSortByContent}
				withGridLayout={withGridLayout}
				layoutChangeValueForEventId={layoutChangeValueForEventId}
				iconLayout={iconLayout}
				searchable={searchable}
				onSearchBounce={onSearchBounce}
				hasFilters={hasFilters}
				onShowFiltersClicked={onShowFiltersClicked}
				showFilters={showFilters}
				filters={filters}
				filterValues={filterValues}
				filtersChanged={filtersChanged}
				subContent={subContent}
				is48HoursPast={is48HoursPast}
				isHomeWidget={isHomeWidget}
				isWithDiscountsFilter={isWithDiscountsFilter}
				customButtons={customButtons}
				isOrdersEnabled={isOrdersEnabled}
				searchQuery={searchQuery}
				setShowFilters={setShowFilters}
				isWithLocationButton={isWithLocationButton}
				showSearchbar={showSearchbar}
				searchPlaceholder={searchPlaceholder}
			>
				{children}
			</SingleStoreContainer>
		);
	}

	if (isSingleProduct) {
		return (
			<SingleProductContainer
				prependStoreContent={prependStoreContent}
				hideOnScroll={hideOnScroll}
				prependContent={prependContent}
				tbl={tbl}
				title={title}
				itemsCountLabel={itemsCountLabel}
				subtitle={subtitle}
				prependStoreSortByContent={prependStoreSortByContent}
				withGridLayout={withGridLayout}
				layoutChangeValueForEventId={layoutChangeValueForEventId}
				iconLayout={iconLayout}
				searchable={searchable}
				onSearchBounce={onSearchBounce}
				hasFilters={hasFilters}
				onShowFiltersClicked={onShowFiltersClicked}
				showFilters={showFilters}
				setShowFilters={setShowFilters}
				filters={filters}
				filterValues={filterValues}
				filtersChanged={filtersChanged}
				subContent={subContent}
				is48HoursPast={is48HoursPast}
				isHomeWidget={isHomeWidget}
				isSingleProduct={isSingleProduct}
				isWithDiscountsFilter={isWithDiscountsFilter}
				isWithShippingFilter={isWithShippingFilter}
				isWithOpenFilter={isWithOpenFilter}
				isWithLocationButton={isWithLocationButton}
				customButtons={customButtons}
				isStoresTable={isStoresTable}
				itemId={itemId}
				searchQuery={searchQuery}
				showSearchbar={showSearchbar}
				setIsTableView={setIsTableView}
				isTableView={isTableView}
				searchPlaceholder={searchPlaceholder}
			>
				{children}
			</SingleProductContainer>
		);
	}

	if (isStoresTable) {
		return (
			<StoresTableContainer
				prependStoreContent={prependStoreContent}
				hideOnScroll={hideOnScroll}
				prependContent={prependContent}
				tbl={tbl}
				title={title}
				itemsCountLabel={itemsCountLabel}
				subtitle={subtitle}
				prependStoreSortByContent={prependStoreSortByContent}
				withGridLayout={withGridLayout}
				layoutChangeValueForEventId={layoutChangeValueForEventId}
				iconLayout={iconLayout}
				searchable={searchable}
				searchPlaceholder={searchPlaceholder}
				onSearchBounce={onSearchBounce}
				hasFilters={hasFilters}
				onShowFiltersClicked={onShowFiltersClicked}
				showFilters={showFilters}
				setShowFilters={setShowFilters}
				filters={filters}
				filterValues={filterValues}
				filtersChanged={filtersChanged}
				subContent={subContent}
				is48HoursPast={is48HoursPast}
				isHomeWidget={isHomeWidget}
				isSingleProduct={isSingleProduct}
				isWithDiscountsFilter={isWithDiscountsFilter}
				isWithShippingFilter={isWithShippingFilter}
				isWithOpenFilter={isWithOpenFilter}
				isWithLocationButton={isWithLocationButton}
				customButtons={customButtons}
				isShabbatPage={isShabbatPage}
				searchQuery={searchQuery}
				showSearchbar={showSearchbar}
				setIsTableView={setIsTableView}
				isTableView={isTableView}
				withMap={withMap}
			>
				{children}
			</StoresTableContainer>
		);
	}

	return (
		<div className="rounded-md relative">
			{prependStoreContent}
			{hideOnScroll && prependContent}
			<div
				className={classNames(
					"bg-white rounded-md sticky z-20",
					plainHeader ? "" : "shadow-sm",
					isInIframe && isHomeWidget
						? "top-[40px]"
						: isInIframe
						? "top-0"
						: isHomeWidget
						? `top-[40px]`
						: "top-[55px] lg:top-[64px]",
					withGridLayout && "z-40",
				)}
			>
				{!hideOnScroll && prependContent}
				<div
					className={`px-1 flex gap-2 pr-2 py-2 sm:items-center items-start flex-wrap md:flex-nowrap justify-between md:flex-row`}
				>
					<div
						className={`flex items-center sm:items-start flex-row sm:flex-col justify-between w-full`}
					>
						<div className={`flex w-full gap-2 items-center`}>
							<h2 className="flex items-center text-base font-semibold text-gray-800 flex-shrink-0">
								{!!tbl.ready && title}
								{!!tbl.ready && !isMainTable && (
									<span className="mr-1.5 text-gray-500 font-medium text-sm">
										{itemsCountLabel}
									</span>
								)}
								<br />
							</h2>
							{tbl?.lastUpdateAt ? (
								<div className="flex items-center flex-nowrap whitespace-nowrap flex-shrink-0">
									<StoresUpdateTime lastUpdateAt={tbl.lastUpdateAt} />
								</div>
							) : null}
							<div className="flex">
								{!!subtitle && (
									<p className="mt-1 text-sm text-gray-500">{subtitle}</p>
								)}
							</div>
							<div className="flex md:hidden items-center gap-2 justify-end grow">
								<span className="flex md:hidden gap-2">
									{!!customButtons ? customButtons : null}
									{!!prependSortByContent && prependSortByContent}
								</span>
								<LayoutButton
									layoutOptions={tbl.layoutOptions}
									layout={tbl.layout}
									changeLayout={tbl.changeLayout}
									loading={!tbl.ready}
								/>
							</div>
						</div>
					</div>
					{(!!searchable ||
						!!hasFilters ||
						!!isWithDiscountsFilter ||
						!!isWithLocationButton ||
						!!withGridLayout ||
						!!isWithLocationButton ||
						prependSortByContent ||
						!!isTableWithMap) && (
						<div
							className={`flex items-center gap-2 grow justify-end flex-wrap sm:flex-nowrap w-full`}
						>
							<div className="flex items-center gap-2 grow justify-end max-w-full sm:w-full relative">
								{!!searchable && !!tbl.ready && (
									<DebounceInput
										searchQuery={searchQuery}
										placeholder={searchPlaceholder}
										onBounce={onSearchBounce}
										className={`sm:min-w-[180px] grow md:max-w-sm sm:max-w-[230px]`}
									/>
								)}
								<div className="flex-shrink-0 flex self-stretch items-stretch">
									{!!prependStoreSortByContent && (
										<span className="z-40">{prependStoreSortByContent}</span>
									)}
								</div>
								{!!hasFilters && (
									<FiltersButton
										showFilters={showFilters}
										toggleFilters={() => setShowFilters(!showFilters)}
										filterValues={filterValues}
										className="self-stretch"
									/>
								)}

								{!!isWithDiscountsFilter && (
									<StoresQuickFilter
										tbl={tbl}
										className="w-full"
										filterType="discount"
									/>
								)}
							</div>
							<div className="hidden md:flex items-center gap-2 justify-end w-fit">
								{!!customButtons ? customButtons : null}
								{!!prependSortByContent && prependSortByContent}
								<LayoutButton
									layoutOptions={tbl.layoutOptions}
									layout={tbl.layout}
									changeLayout={tbl.changeLayout}
									loading={!tbl.ready}
								/>
							</div>
							{tbl?.ready && isTableWithMap && (
								<span className="flex items-center">
									<MapTableIcon
										isTableView={isTableView}
										setIsTableView={setIsTableView}
									/>
								</span>
							)}
						</div>
					)}
				</div>
				<div>{subContent}</div>
			</div>
			{!!filterValues.length && (
				<FilterTags
					filterValues={filterValues}
					isQuickFilter={isQuickfilter}
					isSingleStore={isSingleStore}
					onFiltersChanged={onFiltersChanged}
				/>
			)}

			<div className="py-2 flex lmd:gap-2 relative">
				{!!hasFilters && (
					<TableFiltersWrapper
						filters={filters}
						values={filterValues}
						onChange={filtersChanged}
						isOpen={showFilters}
						setIsOpen={setShowFilters}
						isSingleStore={isSingleStore}
					/>
				)}

				<div className="w-full">
					{!!is48HoursPast ? <StoreDidNotUpdateMessage /> : children}
				</div>
			</div>
		</div>
	);
}
