import React, { createContext, useContext } from "react";

const DesiredCartContext = createContext({
	products: [],
	isResults: false,
	isDrawerOpen: false,
	isInitialized: false,
	isResultsButtonDisabled: false,
	availableStoresCount: 0,
	availableStoresCountLoading: false,
	setProducts: () => {},
	setIsInitialized: () => {},
	handleSetProducts: () => {},
	handleToggleResults: () => {},
	setIsDrawerOpen: isOpen => {},
});

export function DesiredCartProvider({ value, children }) {
	return (
		<DesiredCartContext.Provider value={value}>
			{children}
		</DesiredCartContext.Provider>
	);
}

export function useDesiredCartContext() {
	const context = useContext(DesiredCartContext);
	if (context === null) {
		throw new Error(
			"useDesiredCartContext must be used within a DesiredCartProvider",
		);
	}
	return context;
}
