import React from "react";
import FilterTag from "./FilterTag";
import { classNames } from "util/common";
import { TrashIcon } from "@heroicons/react/outline";

function FilterTags({
	filterValues,
	isQuickFilter,
	isSingleStore = false,
	onFiltersChanged,
	isSingleProduct = false,
	disabled = false,
}) {
	let newFilterValues = [...filterValues];
	if (isQuickFilter && !isSingleStore) {
		newFilterValues = filterValues.filter(
			filter =>
				filter.property !== "family" &&
				filter.property !== "product_type" &&
				filter.property !== "category",
		);
	}
	const removeFilter = (filterGroupIndex, index) => {
		if (
			!!newFilterValues[filterGroupIndex]?.labels[index] &&
			!!newFilterValues[filterGroupIndex]?.value[index]
		) {
			newFilterValues[filterGroupIndex].labels.splice(index, 1);
			newFilterValues[filterGroupIndex].value.splice(index, 1);
			if (newFilterValues[filterGroupIndex].value.length === 0) {
				newFilterValues.splice(filterGroupIndex, 1);
			}

			onFiltersChanged(newFilterValues);
		}
	};

	const clearAllFilters = () => {
		onFiltersChanged([]);
	};

	const countActiveFilters = () => {
		let count = 0;

		newFilterValues.forEach(filter => {
			if (!filter.value || !filter.labels) return;
			if (filter.value.length !== filter.labels.length) return;

			// Count only values that have corresponding labels
			for (let i = 0; i < filter.value.length; i++) {
				if (filter.value[i] && filter.labels[i]) {
					if (isSingleStore) {
						if (
							filter.property !== "store__has_delivery" &&
							filter.property !== "is_discount_exists" &&
							filter.property !== "is_active" &&
							filter.property !== "has_delivery"
						) {
							count++;
						}
					} else {
						if (
							filter.property !== "family" &&
							filter.property !== "product_type" &&
							filter.property !== "category" &&
							filter.property !== "store__has_delivery" &&
							filter.property !== "has_delivery" &&
							filter.property !== "is_discount_exists" &&
							filter.property !== "in_stock" &&
							filter.property !== "distance" &&
							filter.property !== "is_active" &&
							filter.property !== "store__is_active"
						) {
							count++;
						}
					}
				}
			}
		});
		return count;
	};

	const count = countActiveFilters();

	return (
		<div
			className={classNames(
				"rounded-md w-full bg-white flex items-center gap-2",
				count == 0 && "hidden",
				count > 0 && "p-1 mt-2",
				isSingleStore && "mb-2",
			)}
		>
			{count > 0 && (
				<div className="font-semibold text-sm text-jane-600 mr-2 block">
					מסננים:
				</div>
			)}
			<div className="flex items-center justify-between overflow-x-auto custom-scroll">
				<div className="flex justify-start  gap-y-1 gap-x-2 flex-wrap lmd:flex-nowrap">
					{newFilterValues.length > 0 && (
						<>
							{count >= 3 && (
								<span
									className="flex items-center gap-1 rounded-full px-2 text-[13px] h-6 cursor-pointer whitespace-nowrap text-white bg-jane-400 transition-colors hover:bg-jane-500"
									onClick={() => {
										if (disabled) return;
										clearAllFilters();
									}}
								>
									<TrashIcon className="w-4 h-4" />
									נקה הכל
								</span>
							)}
							{newFilterValues.map((v, i) => {
								if (
									v.property === "is_active" ||
									v.property === "distance" ||
									v.property === "family" ||
									v.property === "product_type" ||
									(v.property !== "in_stock" && v.property === "category") ||
									(v.property === "store__has_delivery" && isSingleProduct) ||
									v.property === "has_delivery" ||
									v.property === "store__is_active"
								)
									return;
								return (
									!!v.labels &&
									v.labels.map((val, j) => {
										if (!val) return null;
										return (
											<FilterTag
												key={j}
												label={val}
												removeFilter={() => removeFilter(i, j)}
												disabled={disabled}
											/>
										);
									})
								);
							})}
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default FilterTags;
